<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Plan de Cuentas - Contabilidad</h4>
            <div class="small text-muted">Administración del Plan de Cuentas</div>
          </b-col>
          <b-col sm="5">
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Plan de Cuentas">
              <b-dropdown-item @click="add()"><i class="fa fa-plus"></i> Agregar Cuenta</b-dropdown-item>
              <b-dropdown-item @click="openImportCSV()"><i class="fa fa-file-excel-o"></i> Gestor de Importaciones</b-dropdown-item>
            </b-dropdown>            
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">        
        <b-row>
          <b-col>            
            <vue-tree-list
              @click="edit"
              @delete-node="remove"              
              :model="accountsTree"
              default-tree-node-name="Agregar Grupo"
              default-leaf-node-name="Agregar Cuenta"
              v-bind:default-expanded="false"
            >                                
              <template v-slot:leafNameDisplay="slotProps">                
                <span v-if="slotProps.model.reference" :class="{ accountPlanActive: slotProps.model.active == 0 }">
                  <b>{{ slotProps.model.code }}</b> - {{ slotProps.model.name }} ({{ slotProps.model.reference}})
                </span>
                <span v-else :class="{ accountPlanActive: slotProps.model.active == 0 }">
                  <b>{{ slotProps.model.code }}</b> - {{ slotProps.model.name }}
                </span>
              </template>

              <span class="icon" slot="treeNodeIcon">📂</span>    
              <span class="icon" slot="leafNodeIcon">📃</span>
            </vue-tree-list>                     
            
            <div v-if="accounts">
              <b-alert show v-if="!accounts.length" variant="warning">
                No se encontró el plan de cuentas.               
              </b-alert>
            </div>

          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row class="mt-3">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD IMPORTACIONES -->
      <b-modal  v-model="modal.formImport.active"
                header-bg-variant="dark"
                header-text-variant="white"
                size="lg">
        <div slot="modal-header">
          {{this.modal.formImport.title}}
        </div>
        
        <b-tabs pills card vertical>
          <b-tab title="Plan de Cuentas" @click="crud.formImport.type='import_store_account_plan'" active >
            <b-card-text>
              <b-row>       
                <b-col md="12" class="mb-2">
                  <b-alert show variant="info">
                      <h4 class="alert-heading">Importación de Plan de Cuentas</h4>
                      
                      <div>
                        <b-icon icon="arrow-right-short"/> El sistema importará todos los registros cargados en el archivo.
                      </div>
                      <div>
                        <b-icon icon="arrow-right-short"/> Esta importación permite agregar <b>grupos de cuentas</b> con sus <b>cuentas</b>.
                      </div>                      
                      <div>
                        <b-icon icon="arrow-right-short"/> Si el <b>código</b> ya existe en la base de datos, se omite dicho registro. 
                      </div>
                      
                      <hr>

                      <div class="mb-0">
                        Descargue el <a href="javascript:void(0);" @click="goDownloadFormat('examples-imports/accounting/csv-format-accounts.csv')"><b>formato de ejemplo</b></a> para esta importación.
                      </div>                                                                  
                    </b-alert>                  
                </b-col>
                <b-col md="12" class="mb-2">                  
                  <b-form-group label="Archivo CSV [Plan de Cuentas]">                
                    <b-form-file v-model="crud.formImport.file"
                                  size="sm"
                                  :state="Boolean(crud.formImport.file)"
                                  placeholder="Elija un archivo o sueltelo aquí..."
                                  drop-placeholder="Suelta el archivo aquí...">
                    </b-form-file>                    
                  </b-form-group>         
                </b-col> 
              </b-row>              
            </b-card-text>
          </b-tab>
        </b-tabs>


        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formImport.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="importCSV()">Importar</b-button>          
        </div>

      </b-modal>

      <!-- CRUD - EDIT -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white"
               size="lg">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="8">
              <b-form-group label="TIPO DE CUENTA">                        
                <b-form-select v-model="crud.form.type" 
                              :options="arr.select.type" 
                              size="sm"
                              @change="changeTypeAccount()"></b-form-select>                
              </b-form-group>                                      
          </b-col>
          <b-col md="4">
            <b-form-checkbox v-model="crud.form.active" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              Activar
            </b-form-checkbox>            
          </b-col>

          <b-col md="12" :key="crud.form.parent_id">
            <FindObject render="search"
                        type="accountingAccountsNotImputation" 
                        @select-object="loadAccountingAccounts($event)" 
                        :where="conditionAccountingAccounts"
                        :valueID="crud.form.parent_id"/>              
          </b-col>

          <b-col md="12">
            <hr>
          </b-col>
                    
          <b-col md="6">
              <b-form-group label="Código">                
                <b-form-input type="text"                              
                              size="sm"
                              v-model="crud.form.code">
                </b-form-input>            
              </b-form-group>
          </b-col>          
          <b-col md="6">
              <b-form-group label="Referencia">                
                <b-form-input type="text"                              
                              size="sm"
                              v-model="crud.form.reference">
                </b-form-input>            
              </b-form-group>
          </b-col>           
          <b-col md="6">
              <b-form-group label="Nombre">                
                <b-form-input type="text"                              
                              size="sm"
                              v-model="crud.form.name">
                </b-form-input>            
              </b-form-group>
          </b-col>                              
          <b-col md="6">
              <b-form-group label="Abreviación">                
                <b-form-input type="text"                              
                              size="sm"
                              v-model="crud.form.abbreviation">
                </b-form-input>            
              </b-form-group>
          </b-col>                                        
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false" size="sm">Cancelar</b-button>
          <b-button variant="dark" @click="save()" size="sm">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject'  

  import Vue from 'vue'
  import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'
  Vue.use(VueTreeList)

  export default {
    components: {
      VueTreeList,
      FindObject
    },    
    data: () => {      
      return {      
        access: {
          module_id: Modules.CONTABILIDAD,
          profile_id: Profiles.PERSONAL,
          view_reference: 'accountPlan',
          elements: {}
        },
        crud: {
          form: {
            id: 0,
            code: '',
            reference: '',
            name: '',
            abbreviation: '',
            type: '',
            parent_id: 0,
            parent: null,
            active: true,
          },
          formImport: {
            file: '',
            type: ''            
          }            
        }, 
        modal: {
          form: {
            active: false,
            title: ''            
          },
          formImport: {
            active: false,
            title: ''
          }          
        },   
        arr: {
          select: {
            type: [
              { value: 'Activo', text: 'ACTIVO' },
              { value: 'Pasivo', text: 'PASIVO' },                            
              { value: 'Patrimonio', text: 'PATRIMONIO NETO' },
              { value: 'Ingresos', text: 'INGRESOS' },                            
              { value: 'Egresos', text: 'EGRESOS' },                 
              { value: 'Resultado', text: 'RESULTADO' },
            ],            
          }          
        },             
        accounts: null,
        accountsTree: new Tree([]),
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)    
      /* Fin configuracion */
    },  
    mounted() {                            
      this.loadPlanAccount()
    },
    computed :{
      conditionAccountingAccounts(){
        if(this.crud.form.type) {
          return [
            {field: 'active', condition: true},
            {field: 'type', condition: this.crud.form.type}
          ];
        } else {
          return [
            {field: 'active', condition: true},
          ]
        }
      },         
    },
    methods: {                   
      loadPlanAccount(){
        let loader = this.$loading.show()   
   
        var result = serviceAPI.filtrarPlanCuenta()        
        result.then((response) => {
          loader.hide()

          this.accounts = [];  
          this.accountsTree = new Tree(this.accounts);

          var data = response.data                                
          if(data.length) {
            this.configAccount(data)                  
          }          
        })
        .catch(error => {         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });          
      },
      configAccount(data) { 
        this.accounts = []          

        data.forEach((element, index) => {                                                          
          this.accounts.push({            
            id: element.id,      
            code: element.code,    
            reference: element.reference,      
            name: element.name,
            abbreviation: element.abbreviation,
            type: element.type,
            parent_id: element.parent_id,
            active: element.active,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: false,                     
            children: [],
          })

          this.getChildren(this.accounts[index].children, element.children_recurrent)
        })                  

        this.accountsTree = new Tree(this.accounts)
      },      
      getChildren(obj, data) {                                     
        if(data) {        
          data.forEach((element, index) => {        
            if(element.children_recurrent.length) {            
              obj.push({
                id: element.id,      
                code: element.code,    
                reference: element.reference,      
                name: element.name,
                abbreviation: element.abbreviation,
                type: element.type,
                parent_id: element.parent_id,
                active: element.active,
                dragDisabled: true,
                addTreeNodeDisabled: true,
                addLeafNodeDisabled: true,
                editNodeDisabled: true,
                delNodeDisabled: false,                
                children: [],
              })
              
              obj.children = this.getChildren(obj[index].children, element.children_recurrent)
            } else {
              obj.push({
                id: element.id,      
                code: element.code,    
                reference: element.reference,      
                name: element.name,
                abbreviation: element.abbreviation,
                type: element.type,
                parent_id: element.parent_id,
                active: element.active,
                dragDisabled: true,
                addTreeNodeDisabled: true,
                addLeafNodeDisabled: true,
                editNodeDisabled: true,
                delNodeDisabled: false,                  
                isLeaf: true,            
                children: [],
              })              
            }
          });     
        }   
      },
          
      add() {
        this.crud.form.id = 0
        this.crud.form.code = ''
        this.crud.form.reference = ''
        this.crud.form.name = ''
        this.crud.form.abbreviation = ''
        this.crud.form.parent_id = 0
        this.crud.form.active = true
        this.crud.form.type = ''

        this.modal.form.title = "Agregar Cuenta"
        this.modal.form.active = true
      },
      edit(params) {
        this.crud.form.id = params.id
        this.crud.form.code = params.code
        this.crud.form.reference = params.reference
        this.crud.form.name = params.name
        this.crud.form.abbreviation = params.abbreviation     
        this.crud.form.parent_id = params.parent_id
        this.crud.form.active = (params.active == 1)
        this.crud.form.type = params.type        

        if(params.children) {
          this.modal.form.title = "Editar Grupo de Cuenta"
        } else {
          this.modal.form.title = "Editar Cuenta"
        }
        
        this.modal.form.active = true
      },
      save() {
        let loader = this.$loading.show();
        
        if (this.crud.form.id) {
          var result = serviceAPI.editarPlanCuenta(this.crud.form);
        } else {
          var result = serviceAPI.agregarPlanCuenta(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.loadPlanAccount()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      remove(params) {        
        var msj = ''
        var title = ''
        if(params.children) {
          msj = "¿Desea Borrar Grupo de Cuenta '"+ params.name +"'?"
          title = 'Grupo de Cuenta'
        } else {
          msj = "¿Desea Borrar Cuenta '"+ params.name +"'?"
          title = 'Cuenta'
        }

        this.$bvModal.msgBoxConfirm(msj, {
          title: 'Borrar ' + title,
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {

            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPlanCuenta(params.id);

            result.then((response) => {              
              loader.hide()
              this.loadPlanAccount()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },

      changeTypeAccount() {
        this.crud.form.parent = null
        this.crud.form.parent_id = 0        
      },
      loadAccountingAccounts(object) {
        if(object){
          this.crud.form.parent = object
          this.crud.form.parent_id = object.id             
        } else {
          this.crud.form.parent = null
          this.crud.form.parent_id = 0
        }
      }, 
            
      // Import By CSV
      openImportCSV() {
        this.crud.formImport.type = 'import_store_account_plan'
        this.crud.formImport.file = ''
            
        this.modal.formImport.title = "Gestor de Importaciones"
        this.modal.formImport.active = true
      },
      importCSV() {        
        this.$bvModal.msgBoxConfirm('¿Continuar con la importación?', {
          title: 'Importar desde CSV',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'IMPORTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {                       
            let loader = this.$loading.show();

            var file = new FormData();
            file.append("file", this.crud.formImport.file);            
              
            var result = serviceAPI.importarPlanCuenta(JSON.stringify(this.crud.formImport), file);

            result.then((response) => {              
              this.modal.formImport.active = false              
              loader.hide()
              this.loadPlanAccount()
              this.$awn.success("Archivo importado con éxito");
            })
            .catch(error => {    
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {         
          this.$awn.alert(Error.showError(error));
        })        
      },
      goDownloadFormat(value) {
        var win = window.open(Helper.getPathPublic(value), '_blank');        
        win.focus();
      }      
    }  
  }      
</script>
<style scoped>
  .accountPlanActive {
    color: white;
    background: red;
  }
</style>